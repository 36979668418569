// src/LogoutPage.js
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useApi } from "./hooks/apiClient";
import { useContext } from "react";
import { AuthContext } from "./hooks/AuthContext";

function LogoutPage() {
  const { authorizedFetch } = useApi();
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    // Call the logout endpoint on the server to invalidate the token
    authorizedFetch(
      "https://lms.newdevelop.nl/wp-json/newlearn-lms/v1/api/auth/logout",
      {
        method: "POST",
      }
    )
      .then((resp) => resp.json())
      .then((data) => {
        // Ideally, check if data.success is true or not
        console.log("Server logout response:", data);
      })
      .catch((error) => {
        console.error("Logout error:", error);
      })
      .finally(() => {
        // Either way, clear our local auth data
        logout();
        // Then redirect to /login (or /)
        navigate("/login");
      });
  }, [authorizedFetch, logout, navigate]);

  return null;
}

export default LogoutPage;
