// LoginPage.js
import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Container,
  Form,
  Button,
  Row,
  Col,
  FloatingLabel,
  Stack,
  Spinner,
} from "react-bootstrap";
import { AuthContext } from "./hooks/AuthContext";

function LoginPage() {
  const navigate = useNavigate();
  const { token, login } = useContext(AuthContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  // 1) If user is already logged in, redirect them to dashboard
  useEffect(() => {
    if (token) {
      navigate("/dashboard");
    }
  }, [token, navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrorMsg("");

    try {
      const response = await fetch(
        "https://lms.newdevelop.nl/wp-json/newlearn-lms/v1/api/auth/login",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, password }),
        }
      );
      const data = await response.json();

      if (data.success) {
        const { token: userToken, ...userData } = data.data;
        login(userToken, userData);
        navigate("/dashboard");
      } else {
        setErrorMsg(
          "Controleer je e-mailadres en wachtwoord en probeer opnieuw."
        );
      }
    } catch (error) {
      console.error(error);
      setErrorMsg("An unexpected error occurred.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container className="mt-5">
      <Row className="justify-content-md-center">
        <Col md={6} className="px-4">
          <Stack gap={4}>
            <div>
              <h4 className="mb-2">Welkom bij Learning Steps! 👋🏻</h4>
              <p className="mb-0 text-body-secondary">
                Login met je e-mailadres en wachtwoord om verder te gaan.
              </p>
            </div>
            {errorMsg && (
              <div className="alert alert-danger" role="alert">
                {errorMsg}
              </div>
            )}
            <Form onSubmit={handleLogin}>
              <Stack gap={4}>
                <FloatingLabel controlId="floatingInput" label="E-mailadres">
                  <Form.Control
                    type="email"
                    placeholder="naam@voorbeeld.nl"
                    disabled={isLoading}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FloatingLabel>

                <FloatingLabel controlId="floatingPassword" label="Wachtwoord">
                  <Form.Control
                    type="password"
                    placeholder="Wachtwoord"
                    disabled={isLoading}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </FloatingLabel>

                <div className="d-flex justify-content-end align-items-center">
                  {/* <Form.Group controlId="formBasicCheckbox">
                    <Form.Check
                      type="checkbox"
                      label="Ingelogd blijven"
                      disabled={isLoading}
                    />
                  </Form.Group> */}

                  <Link
                    to="/forgot-password"
                    className="small link-underline link-underline-opacity-0 link-underline-opacity-75-hover"
                  >
                    Wachtwoord vergeten?
                  </Link>
                </div>

                <Button
                  variant="primary"
                  type="submit"
                  size="lg"
                  className="w-100 rounded-2"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <>
                      <Spinner animation="border" role="status" size="sm">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>{" "}
                      Bezig met inloggen...
                    </>
                  ) : (
                    "Login"
                  )}
                </Button>
              </Stack>
            </Form>
            {/* <div className="d-flex align-item-center justify-content-center flex-wrap gap-2">
              <p className="mb-0 text-body-secondary">Nieuw op ons platform?</p>
              <Link
                className="link-underline link-underline-opacity-0 link-underline-opacity-75-hover"
                to="/register"
              >
                Maak een account aan
              </Link>
            </div> */}
          </Stack>
        </Col>
      </Row>
    </Container>
  );
}

export default LoginPage;
