// src/DashboardPage.js
import React, { useContext } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  // Form,
  // FloatingLabel,
  ProgressBar,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "./hooks/AuthContext";

function DashboardPage() {
  // Grab user info directly from context
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  // If not logged in or no user object, handle that
  React.useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [user, navigate]);

  // Avoid rendering any user details until "user" is non-null
  if (!user) {
    return null;
  }

  return (
    <Container>
      <Row className="justify-content-center">
        <Col md={12} lg={8}>
          <div className="d-flex justify-content-between align-items-center">
            <h1 className="mb-0">Mijn account</h1>
            <Button
              as={Link}
              variant="outline-primary"
              className="rounded-3"
              to="/logout"
            >
              Uitloggen
            </Button>
          </div>

          <hr className="border border-gray opacity-75 mt-4 mb-2" />
          <h3 className="h5 fw-bold mb-3">Gebruikersgegevens</h3>
          <Row className="gap-3">
            <div className="d-md-flex">
              <Col md={4} className="text-gray-dark">
                Voornaam
              </Col>
              <Col md={8}>{user.first_name}</Col>
            </div>
            <div className="d-md-flex">
              <Col md={4} className="text-gray-dark">
                Achternaam
              </Col>
              <Col md={8}>{user.last_name}</Col>
            </div>
            <div className="d-md-flex">
              <Col md={4} className="text-gray-dark">
                Email
              </Col>
              <Col md={8}>{user.user_email}</Col>
            </div>
            <div className="d-md-flex">
              <Col md={4} className="text-gray-dark">
                Organisatie
              </Col>
              <Col md={8}>
                {user.organisation && user.organisation.length > 0
                  ? user.organisation[0].title
                  : "–"}
              </Col>
            </div>

            <div className="d-md-flex">
              <Col md={4} className="text-gray-dark">
                Locatie/afdeling
              </Col>
              <Col md={8}>
                {user.location && user.location.length > 0
                  ? user.location[0].title
                  : "–"}
              </Col>
            </div>

            <div className="d-md-flex">
              <Col md={4} className="text-gray-dark">
                Gebruikers rol(len)
              </Col>
              <Col md={8}>
                {Array.isArray(user.roles) && user.roles.length > 0
                  ? user.roles.join(", ")
                  : "–"}
              </Col>
            </div>
          </Row>

          {/* <hr className="border border-gray opacity-75 mt-4 mb-2" />
          <h3 className="h5 fw-bold mb-3">Wachtwoord</h3>
          <Row>
            <Col md>
              <p>Nieuw wachtwoord</p>
            </Col>
            <Col md>
              <FloatingLabel controlId="floatingPassword" label="Wachtwoord">
                <Form.Control type="password" placeholder="Wachtwoord" />
              </FloatingLabel>
            </Col>
            <Col md>
              <Button className="rounded-1" variant="primary" type="submit">
                Wachtwoord wijzigen
              </Button>
            </Col>
          </Row> */}

          <hr className="border border-gray opacity-75 mt-4 mb-2" />
          <h3 className="h5 fw-bold mb-3">Toegang</h3>
          <Row className="gap-3">
            <div className="d-md-flex">
              <Col md={4} className="text-gray-dark">
                Mijn groep(en)
              </Col>
              <Col md={8}>Schuldhulp Maatje</Col>
            </div>
          </Row>

          <hr className="border border-gray opacity-75 mt-4 mb-2" />
          <h3 className="h5 fw-bold mb-3">Cursussen</h3>

          <div className="mb-4">
            <div className="mb-2">
              <a href="#!" className="fw-bold mb-2">
                Basistraining
              </a>
              <div className="text-gray-dark">
                <small>Start: 12-08-2023 | Afggerond: 23-09-2023</small>
              </div>
            </div>
            <div className="d-flex flex-md-row flex-column align-items-md-center bg-progress-light p-2 px-4 rounded-3">
              <ProgressBar
                now={100}
                variant="success"
                className="flex-grow-1 me-md-3 me-0 rounded-0 bg-progress-dark"
                style={{ height: "10px" }}
              />
              <span className="fw-semibold small text-end mt-md-0 mt-1">
                100% VOLTOOID 18/18 stappen
              </span>
            </div>
          </div>

          <div className="mb-4">
            <div className="mb-2">
              <a href="#!" className="fw-bold mb-2">
                Gevorden training
              </a>
              <div className="text-gray-dark">
                <small>Start: -- | Afggerond: --</small>
              </div>
            </div>
            <div className="d-flex flex-md-row flex-column align-items-md-center bg-progress-light p-2 px-4 rounded-3">
              <ProgressBar
                now={0}
                variant="secondary"
                className="flex-grow-1 me-md-3 me-0 rounded-0 bg-progress-dark"
                style={{ height: "10px" }}
              />
              <span className="fw-semibold small text-end mt-md-0 mt-1">
                0% NIET GESTART 0/10 stappen
              </span>
            </div>
          </div>

          <div className="mb-4">
            <div className="mb-2">
              <a href="#!" className="fw-bold mb-2">
                Belasting voor beginners
              </a>
              <div className="text-gray-dark">
                <small>Start: 12-08-2023 | Afggerond: --</small>
              </div>
            </div>
            <div className="d-flex flex-md-row flex-column align-items-md-center bg-progress-light p-2 px-4 rounded-3">
              <ProgressBar
                now={25}
                variant="secondary"
                className="flex-grow-1 me-md-3 me-0 rounded-0 bg-progress-dark"
                style={{ height: "10px" }}
              />
              <span className="fw-semibold small text-end mt-md-0 mt-1">
                25% MEE BEZIG 3/12 stappen
              </span>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default DashboardPage;
