import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";

import parse from "html-react-parser";
import DOMPurify from "dompurify";

import {
  Container,
  Row,
  Col,
  // Form
} from "react-bootstrap";

import CourseOutline from "./components/CourseOutline";
import ProgressBar from "./components/ProgressBar";

function LessonPage() {
  const { id, chapterId, lessonId } = useParams();
  const [course, setCourse] = useState(null);
  const [lesson, setLesson] = useState(null);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    fetch(
      `https://lms.newdevelop.nl/wp-json/newlearn-lms/v1/api/course/frontend/${id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.token}`, // Add the Authorization header with a placeholder token
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        const courseData = data.data;
        setCourse(courseData);
        const chapterData = courseData.chapters.find(
          (chapter) => chapter.id === chapterId
        );
        const lessonData = chapterData.lessons.find(
          (lesson) => lesson.id === lessonId
        );
        setLesson(lessonData);
        setLoading(false);
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
        setLoading(false);
      });
  }, [id, chapterId, lessonId]);

  const sanitizeAndParseContent = (content) => {
    const contentWithBreaks = content.replace(/\r?\n/g, "<br>");
    const sanitizedContent = DOMPurify.sanitize(contentWithBreaks);
    return parse(sanitizedContent);
  };

  const [progress] = useState(50);

  const handlePreviousClick = () => {
    const chapter = course.chapters.find((chapter) => chapter.id === chapterId);
    const lessonIndex = chapter.lessons.findIndex(
      (lesson) => lesson.id === lessonId
    );
    if (lessonIndex > 0) {
      navigate(
        `/course/${id}/chapter/${chapterId}/lesson/${
          chapter.lessons[lessonIndex - 1].id
        }`
      );
    } else {
      navigate(`/course/${id}/chapter/${chapterId}`);
    }
  };

  const handleNextClick = () => {
    const chapter = course.chapters.find((chapter) => chapter.id === chapterId);
    const lessonIndex = chapter.lessons.findIndex(
      (lesson) => lesson.id === lessonId
    );
    if (lessonIndex < chapter.lessons.length - 1) {
      navigate(
        `/course/${id}/chapter/${chapterId}/lesson/${
          chapter.lessons[lessonIndex + 1].id
        }`
      );
    } else {
      const chapterIndex = course.chapters.findIndex(
        (chapter) => chapter.id === chapterId
      );
      if (chapterIndex < course.chapters.length - 1) {
        navigate(
          `/course/${id}/chapter/${course.chapters[chapterIndex + 1].id}`
        );
      }
    }
  };

  const isNextButtonDisabled = () => {
    const currentChapterIndex = course.chapters.findIndex(
      (chapter) => chapter.id === chapterId
    );
    const currentChapter = course.chapters[currentChapterIndex];
    const lessonIndex = currentChapter.lessons.findIndex(
      (lesson) => lesson.id === lessonId
    );

    const isLastLessonInChapter =
      lessonIndex === currentChapter.lessons.length - 1;
    const isLastChapter = currentChapterIndex === course.chapters.length - 1;

    return isLastLessonInChapter && isLastChapter;
  };

  if (loading) {
    return <div className="d-none">Loading...</div>;
  }

  if (!lesson) {
    return <div>Lesson not found</div>;
  }

  const sanitizedContent = sanitizeAndParseContent(lesson.content);

  return (
    <>
      <ProgressBar progress={progress} />
      <Container className="my-5">
        <Row>
          <Col md={4}>
            <CourseOutline
              courseData={course.chapters}
              courseTitle={course.title}
            />
          </Col>
          <Col md={8}>
            <h1 className="fw-bold mb-3">{lesson.title}</h1>
            {lesson.image_url && (
              <img
                src={lesson.image_url}
                alt={lesson.title}
                className="img-chapter mb-4 img-fluid rounded-4 w-100 object-fit-cover"
              />
            )}
            <div className="lesson-content">{sanitizedContent}</div>
            {/* <Form className="bg-primary-light rounded-4 p-4 fs-lg">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label className="fw-bold">Vraag</Form.Label>
                <p>Vul in wat je hierover denkt.</p>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Jouw antwoord..."
                />
              </Form.Group>
            </Form> */}

            <div className="d-flex justify-content-md-between flex-md-row flex-column flex-column-reverse gap-4 mt-5">
              <Link
                className="btn btn-outline-secondary text-body"
                to={`/course/${id}`}
              >
                Terug naar begin
              </Link>
              <div className="d-flex justify-content-between mt-md-0 mt-3">
                <button
                  className="btn btn-secondary"
                  onClick={handlePreviousClick}
                >
                  Vorige
                </button>
                <button
                  className="btn btn-secondary ms-3"
                  onClick={handleNextClick}
                  disabled={isNextButtonDisabled()}
                >
                  Volgende
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default LessonPage;
