import React, { useState, useEffect } from "react";

import { useParams, Link, useNavigate } from "react-router-dom";

// import PlaceholderImage from "./assets/placeholder.svg";
import { Container, Row, Col } from "react-bootstrap";

import CourseOutline from "./components/CourseOutline";

import ProgressBar from "./components/ProgressBar";

function ChapterPage() {
  const { id, chapterId } = useParams();
  const [course, setCourse] = useState(null);
  const [chapter, setChapter] = useState(null);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    fetch(
      `https://lms.newdevelop.nl/wp-json/newlearn-lms/v1/api/course/frontend/${id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.token}`, // Add the Authorization header with a placeholder token
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        const courseData = data.data;
        setCourse(courseData);
        const chapterData = courseData.chapters.find(
          (chapter) => chapter.id === chapterId
        );
        setChapter(chapterData);
        setLoading(false);
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
        setLoading(false);
      });
  }, [id, chapterId]);

  // Mimmic progress bar loading state.
  const [progress] = useState(25);

  if (loading) {
    return <div className="d-none">Loading...</div>;
  }

  if (!chapter) {
    return <div>Chapter not found</div>;
  }

  const handlePreviousClick = () => {
    const chapterIndex = course.chapters.findIndex((c) => c.id === chapterId);
    if (chapterIndex > 0) {
      const previousChapter = course.chapters[chapterIndex - 1];

      // If the previous chapter has lessons, go directly to the last lesson of that chapter:
      if (previousChapter.lessons && previousChapter.lessons.length > 0) {
        const lastLesson =
          previousChapter.lessons[previousChapter.lessons.length - 1];
        navigate(
          `/course/${id}/chapter/${previousChapter.id}/lesson/${lastLesson.id}`
        );
      } else {
        // If no lessons, just navigate to the previous chapter page
        navigate(`/course/${id}/chapter/${previousChapter.id}`);
      }
    } else {
      // If there's no previous chapter, navigate back to the course overview
      navigate(`/course/${id}`);
    }
  };

  const chapterIndex = course.chapters.findIndex((c) => c.id === chapterId);
  const hasNextChapter = chapterIndex < course.chapters.length - 1;
  const hasLessons = chapter.lessons && chapter.lessons.length > 0;

  const handleNextClick = () => {
    // If we have lessons in the current chapter, go to the first one.
    if (hasLessons) {
      const firstLesson = chapter.lessons[0];
      navigate(`/course/${id}/chapter/${chapterId}/lesson/${firstLesson.id}`);
    }
    // Else, if there's a next chapter, go there.
    else if (hasNextChapter) {
      const nextChapter = course.chapters[chapterIndex + 1];
      navigate(`/course/${id}/chapter/${nextChapter.id}`);
    }
  };

  return (
    <>
      <ProgressBar progress={progress} />
      <Container className="my-5">
        <Row>
          <Col md={4}>
            <CourseOutline
              courseData={course.chapters}
              courseTitle={course.title}
            />
          </Col>
          <Col md={8}>
            <h1 className="fw-bold mb-3">{chapter.title}</h1>
            {/* <img
              src={chapter.image_url || PlaceholderImage}
              alt={chapter.title}
              className="img-chapter img-fluid rounded-4 w-100 object-fit-cover"
            /> */}
            {chapter.image_url && (
              <img
                src={chapter.image_url}
                alt={chapter.title}
                className="img-chapter img-fluid rounded-4 w-100 object-fit-cover"
              />
            )}
            {/* <p className="mt-4 fs-lg">{chapter.content}</p> */}
            {/* make chapter.content a set dangoursly inner html */}
            <div
              className="mt-4 fs-lg"
              dangerouslySetInnerHTML={{ __html: chapter.content }}
            ></div>

            <div className="d-flex justify-content-md-between flex-md-row flex-column flex-column-reverse gap-4 mt-5">
              <Link
                to={`/course/${chapter.course_id}`}
                className="btn btn-outline-secondary text-body"
              >
                Terug naar begin
              </Link>
              <div className="d-flex justify-content-between mt-md-0 mt-3">
                <button
                  className="btn btn-secondary"
                  onClick={handlePreviousClick}
                >
                  Vorige
                </button>
                <button
                  className="btn btn-secondary ms-3"
                  onClick={handleNextClick}
                  disabled={!hasLessons && !hasNextChapter}
                >
                  Volgende
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ChapterPage;
