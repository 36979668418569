import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";

// import PlaceholderImage from "./assets/placeholder.svg";
import { Card, Col, Row, Container } from "react-bootstrap";

import {
  BsClock,
  // BsPeople,
  BsPen,
} from "react-icons/bs";

import CourseOutline from "./components/CourseOutline";
import ProgressBar from "./components/ProgressBar";

import { useApi } from "./hooks/apiClient";

function CoursePage() {
  const { id } = useParams();
  const [course, setCourse] = useState(null);
  const [loading, setLoading] = useState(true);

  const { authorizedFetch } = useApi();

  useEffect(() => {
    // Fetch the course data based on the ID
    // Use REACT_APP_AUTH_TOKEN environment variable to fetch the course data
    // fetch(`https://lms.newdevelop.nl/wp-json/newlearn-lms/v1/api/course/frontend/${id}`, {
    //   headers: {
    //     Authorization: `Bearer ${process.env.REACT_APP_AUTH_TOKEN}`, // Add the Authorization header with a placeholder token
    //   },
    // })
    authorizedFetch(
      `https://lms.newdevelop.nl/wp-json/newlearn-lms/v1/api/course/frontend/${id}`
    )
      .then((response) => response.json())
      .then((data) => {
        // const courseData = data.data.find((course) => course.id === id);
        setCourse(data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
        setLoading(false);
      });
  }, [id, authorizedFetch]);

  // Mimmic progress bar loading state.
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    setTimeout(() => {
      setProgress(25);
    }, 200);
  }, []);

  if (loading) {
    return <div className="d-none">Loading...</div>;
  }

  if (!course) {
    return <div>Course not found</div>;
  }

  // Function to check if !courseData.chapters || !courseData.chapters.length are empty or null
  const isCourseDataEmpty = () => {
    return !course.chapters || !course.chapters.length;
  };

  return (
    <>
      <ProgressBar progress={progress} />
      <Container
        className="mb-5"
        // if containerFluid is used, the container will be full width else it will be fixed width
        // fluid
      >
        <Row className="justify-content-center">
          <Col md={12} lg={9}>
            <h2 className="text-center my-5">{course.title}</h2>
            <Card className="course mt-3 border-0">
              {/* <Card.Img
                variant="top"
                src={course.image_url || PlaceholderImage}
              /> */}
              {course.image_url && (
                <Card.Img
                  variant="top"
                  src={course.image_url}
                  alt={course.title}
                />
              )}
              <Card.Body className="p-0 mt-3">
                <div className="small text-muted d-flex gap-2">
                  <span className="d-flex align-items-center">
                    <BsClock className="me-1" /> {course.duration} min.
                  </span>
                  {/* <div className="d-flex align-items-center">
                    <BsPeople className="me-1" />
                    <span>Coordinatoren</span>
                    <span className="me-1">{","}</span>
                    <span>Bestuurders</span>
                  </div> */}
                  <span className="d-flex align-items-center">
                    <BsPen className="me-1" />
                    {course.author}
                  </span>
                </div>
                {course.categories?.length && (
                  <div className="small text-muted d-flex gap-2 mb-3">
                    {course.categories.map((category) => (
                      <Link
                        key={category.id}
                        to={`/?q=${category.title}`}
                        className="text-primary"
                      >
                        {category.title}
                      </Link>
                    ))}
                  </div>
                )}
                <Card.Text className="fs-lg mt-3">
                  <div
                    className="mt-4 fs-lg"
                    dangerouslySetInnerHTML={{ __html: course.content }}
                  ></div>
                </Card.Text>
                <CourseOutline
                  className="mt-5"
                  courseData={course.chapters}
                  courseTitle={course.title}
                />
                <Link
                  className={`btn btn-secondary text-white mt-4 ${
                    isCourseDataEmpty() ? "disabled" : ""
                  }`}
                  // to={
                  //   isCourseDataEmpty() ? "#" : `/course/chapter/${course.id}`
                  // }
                  // to={
                  //   isCourseDataEmpty() ? "#" : `/course/content/${course.id}`
                  // }
                  // to={
                  //   isCourseDataEmpty() ? "#" : `/course/${course.id}/content`
                  // }
                  to={
                    isCourseDataEmpty()
                      ? "#"
                      : `/course/${course?.id}/chapter/${course.chapters[0].id}`
                  }
                >
                  Start cursus
                </Link>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default CoursePage;
