// AuthContext.js
import React, { createContext, useState, useEffect } from "react";

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [authData, setAuthData] = useState(() => {
    const storedToken = localStorage.getItem("token");
    const storedUser = localStorage.getItem("user");
    return {
      token: storedToken || null,
      // We may have incomplete or null user data from previous session
      user: storedUser ? JSON.parse(storedUser) : null,
    };
  });

  const { token, user } = authData;

  // 1) Login function
  const login = (token, user) => {
    setAuthData({ token, user });
    localStorage.setItem("token", token);
    localStorage.setItem("user", JSON.stringify(user));
  };

  // 2) Logout function
  const logout = () => {
    setAuthData({ token: null, user: null });
    localStorage.removeItem("token");
    localStorage.removeItem("user");
  };

  // 3) Fetch full user info after we have a token
  // AuthContext.js
  // ...
  // 3) Fetch full user info after we have a token
  useEffect(() => {
    if (token && (!user || !user.organisation)) {
      fetch("https://lms.newdevelop.nl/wp-json/newlearn-lms/v1/api/user", {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.success) {
            // The new "full" user from server
            const fullUser = response.data;

            // Merge with existing user data from /auth/login
            const mergedUser = { ...user, ...fullUser };

            // Remove sensitive fields. For example:
            // user_pass, user_activation_key, user_status, etc.
            const sanitizedUser = {
              ...mergedUser,
              user_pass: undefined,
              user_activation_key: undefined,
              user_status: undefined,
              user_login: undefined,
              user_nicename: undefined,
              // or delete mergedUser.user_pass
              // or only keep the fields you actually need
            };

            // Now store the sanitized user instead of everything
            setAuthData({ token, user: sanitizedUser });
            localStorage.setItem("user", JSON.stringify(sanitizedUser));
          } else {
            console.error("Failed to fetch user:", response);
          }
        })
        .catch((error) => console.error("Fetch user error:", error));
    }
  }, [token, user]);

  // Provide everything
  return (
    <AuthContext.Provider value={{ token, user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
