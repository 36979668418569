import React from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";

import "./styles/layout.scss";

import CourseList from "./components/CourseList";

// import CourseContent from "./components/CourseContent";

import CoursePage from "./CoursePage";

import ChapterPage from "./ChapterPage";
import LessonPage from "./LessonPage";

import DashboardPage from "./DashboardPage"; // Import the DashboardPage component
import LoginPage from "./LoginPage"; // Import the LoginPage component
import RegisterPage from "./RegisterPage"; // Import the RegisterPage component
import ForgotPasswordPage from "./ForgotPasswordPage"; // Import the ForgotPasswordPage component
import LogoutPage from "./LogoutPage"; // Import the LogoutPage component

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<CourseList />} />
        <Route path="/course/:id" element={<CoursePage />} />
        <Route
          path="/course/:id/chapter/:chapterId"
          element={<ChapterPage />}
        />
        <Route
          path="/course/:id/chapter/:chapterId/lesson/:lessonId"
          element={<LessonPage />}
        />
        {/* <Route
          path="/course/:id/chapter/:chapterId"
          element={<CourseContent />}
        />
        <Route
          path="/course/:id/chapter/:chapterId/lesson/:lessonId"
          element={<CourseContent />}
        /> */}
        {/* <Route path="/course/content/:id" element={<CourseContent />} /> */}
        {/* <Route path="/course/chapter/:id" element={<ChapterPage />} /> */}
        {/* <Route path="/course/lesson/:id" element={<LessonPage />} /> */}
        <Route path="/dashboard" element={<DashboardPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/group/register" element={<RegisterPage />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route path="/logout" element={<LogoutPage />} />
        <Route path="*" element={<h1>404 - Not Found</h1>} />
      </Routes>
    </Router>
  );
}

export default App;
