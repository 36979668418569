// apiClient.js
import { useContext, useCallback } from "react";
import { AuthContext } from "./AuthContext";

export function useApi() {
  const { token } = useContext(AuthContext);

  // Memoize authorizedFetch so it doesn't change on each render
  const authorizedFetch = useCallback(
    (url, options = {}) => {
      const headers = {
        ...options.headers,
        Authorization: token ? `Bearer ${token}` : "",
        "Content-Type": "application/json",
      };
      return fetch(url, {
        ...options,
        headers,
      });
    },
    [token]
  );

  return { authorizedFetch };
}
