/**
 * TODO:
 * - Add functionoality so the g parameter is stored in session storage
 */

import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "./hooks/AuthContext";
import {
  Container,
  Form,
  Button,
  Row,
  Col,
  FloatingLabel,
  Stack,
  Spinner,
} from "react-bootstrap";
import {
  CheckCircle,
  CheckCircleFill,
  Eye,
  EyeSlash,
} from "react-bootstrap-icons";

function RegisterPage() {
  const [isChecking, setIsChecking] = useState(true);

  const [isLoading, setIsLoading] = useState(false);
  const [validated, setValidated] = useState(false);

  // Track the password (first field) and whether it's shown in clear text
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  // Track the confirm password (second field)
  const [confirmPassword, setConfirmPassword] = useState("");

  // Basic password checks for “checklist”
  const meetsLength = password.length >= 8;
  const meetsDigit = /[0-9]/.test(password);
  const meetsSpecial = /[^A-Za-z0-9]/.test(password);

  // Data for dropdowns (fetched if URL param 'g' is present)
  const [organisations, setOrganisations] = useState([]);
  const [locations, setLocations] = useState([]);

  // If there's exactly one organisation/location, we'll auto-select and disable the field
  const isSingleOrg = organisations.length === 1;
  const isSingleLoc = locations.length === 1;

  // Add two new states for the selected org/location
  const [selectedOrg, setSelectedOrg] = useState("");
  const [selectedLoc, setSelectedLoc] = useState("");

  // Filter locations by selected organization_id
  const filteredLocations = useMemo(() => {
    if (!selectedOrg) return [];
    return locations.filter((loc) => loc.organisation_id === selectedOrg);
  }, [locations, selectedOrg]);

  // Use the navigate hook to redirect after successful registration
  const navigate = useNavigate();
  const { login } = useContext(AuthContext);

  // Fetch group data if "g" param is present in URL
  useEffect(() => {
    const url = new URL(window.location.href);
    let gParam = url.searchParams.get("g");

    // If not in URL, check session storage
    if (!gParam) {
      gParam = sessionStorage.getItem("gParam");
    }

    // If still not present, redirect to login
    if (!gParam) {
      navigate("/login");
      return;
    }

    // Store it in session storage (if needed)
    sessionStorage.setItem("gParam", gParam);

    // Remove g from URL for cleanliness
    url.searchParams.delete("g");
    window.history.replaceState({}, "", url.toString());

    // Fetch group data using gParam
    fetch(
      `https://lms.newdevelop.nl/wp-json/newlearn-lms/v1/api/group/uniqueid/${gParam}`
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success && data.data) {
          setOrganisations(data.data.organisation || []);
          setLocations(data.data.location || []);
          // gParam verified – now allow rendering the page
          setIsChecking(false);
        } else {
          // Redirect if invalid response
          navigate("/login");
        }
      })
      .catch((err) => {
        console.error("Group fetch error:", err);
        // Redirect on error as well
        navigate("/login");
      });
  }, [navigate]);

  // Optionally remove any stray g params later
  useEffect(() => {
    const currentUrl = new URL(window.location.href);
    if (currentUrl.searchParams.get("g")) {
      currentUrl.searchParams.delete("g");
      window.history.replaceState({}, "", currentUrl.toString());
    }
  }, []);

  // Until the gParam is verified, render nothing (or a loading indicator)
  if (isChecking) {
    return null; // or return <Spinner animation="border" />;
  }

  const handleRegister = (e) => {
    const form = e.currentTarget;

    if (!form.checkValidity()) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      e.preventDefault();
      setIsLoading(true);

      // Build the request payload using form inputs
      const payload = {
        email: form.elements.email.value,
        password: form.elements.password.value,
        firstname: form.elements.firstName.value,
        lastname: form.elements.lastName.value,
        group_id: sessionStorage.getItem("gParam"),
      };

      // Only include organisations/locations if there are any
      if (organisations.length > 0) {
        payload.organisations = isSingleOrg
          ? [organisations[0].id]
          : form.elements.organisation.value
          ? [form.elements.organisation.value]
          : [];
      }
      if (locations.length > 0) {
        payload.locations = isSingleLoc
          ? [locations[0].id]
          : form.elements.location.value
          ? [form.elements.location.value]
          : [];
      }

      fetch(
        "https://lms.newdevelop.nl/wp-json/newlearn-lms/v1/api/auth/register",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(payload),
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setIsLoading(false);
          if (data.success) {
            // Extract token & user data
            const { token, ...userData } = data.data;
            // Log user in (stores token & user in context)
            login(token, userData);
            // Redirect to /dashboard
            navigate("/dashboard");
          } else {
            // Handle error
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.error("Register error:", err);
        });
    }
    setValidated(true);
  };

  return (
    <Container className="mt-5">
      <Row className="justify-content-md-center">
        <Col md={6} className="px-4">
          <Stack gap={4}>
            <div>
              <h4 className="mb-2">Het avontuur begint hier 🚀</h4>
              <p className="mb-0 text-body-secondary">
                Ervaar hoe leuk en eenvoudig leren kan zijn!
              </p>
            </div>

            {/*
              noValidate + validated => native RB validation
              We'll override .is-valid so we don't get green border 
              (if you add the custom CSS to remove the green border).
            */}
            <Form
              noValidate
              validated={validated}
              autoComplete="on"
              onSubmit={handleRegister}
            >
              <Stack gap={4}>
                {/* ==================== First Name ==================== */}
                <Form.Group controlId="floatingName">
                  <FloatingLabel label="Voornaam">
                    <Form.Control
                      required
                      type="text"
                      placeholder="Voornaam Achternaam"
                      name="firstName"
                      disabled={isLoading}
                    />
                    <Form.Control.Feedback type="invalid">
                      Vul alstublieft uw voornaam in.
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>

                {/* ==================== Last name ==================== */}
                <Form.Group controlId="floatingLastName">
                  <FloatingLabel label="Achternaam">
                    <Form.Control
                      required
                      type="text"
                      placeholder="Achternaam"
                      name="lastName"
                      disabled={isLoading}
                    />
                    <Form.Control.Feedback type="invalid">
                      Vul alstublieft uw achternaam in.
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>

                {/* ==================== E-mail ==================== */}
                <Form.Group controlId="floatingInput">
                  <FloatingLabel label="E-mailadres">
                    <Form.Control
                      required
                      type="email"
                      placeholder="naam@voorbeeld.nl"
                      name="email"
                      disabled={isLoading}
                    />
                    <Form.Control.Feedback type="invalid">
                      Geen geldig e-mailadres ingevuld.
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>

                {/* ==================== Organisation (render only if we have data) ==================== */}
                {organisations.length > 0 && (
                  <Form.Group controlId="floatingOrganisation">
                    <FloatingLabel label="Organisatie">
                      <Form.Select
                        required
                        disabled={isLoading}
                        name="organisation"
                        value={selectedOrg}
                        onChange={(e) => {
                          setSelectedOrg(e.target.value);
                          setSelectedLoc(""); // Reset location when org changes
                        }}
                      >
                        <option value="">Selecteer uw organisatie...</option>
                        {organisations.map((org) => (
                          <option key={org.id} value={org.id}>
                            {org.title}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Selecteer alstublieft uw organisatie.
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>
                )}

                {/* ==================== Location (render only if we have data) ==================== */}
                {locations.length > 0 && (
                  <Form.Group controlId="floatingLocation">
                    <FloatingLabel label="Locatie">
                      <Form.Select
                        required
                        disabled={
                          isLoading ||
                          !selectedOrg ||
                          filteredLocations.length === 0
                        }
                        name="location"
                        value={selectedLoc}
                        onChange={(e) => setSelectedLoc(e.target.value)}
                      >
                        {!selectedOrg ? (
                          <option value="" disabled>
                            Selecteer eerst een organisatie
                          </option>
                        ) : filteredLocations.length === 0 ? (
                          <option value="" disabled>
                            Uw gekozen organisatie heeft geen locatie
                          </option>
                        ) : (
                          <>
                            <option value="">Selecteer uw locatie...</option>
                            {filteredLocations.map((loc) => (
                              <option key={loc.id} value={loc.id}>
                                {loc.title}
                              </option>
                            ))}
                          </>
                        )}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Selecteer alstublieft uw locatie.
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>
                )}

                {/* ============ First Password Field ============ */}
                <Form.Group controlId="floatingPassword">
                  <FloatingLabel
                    label="Wachtwoord"
                    className="position-relative"
                  >
                    <Form.Control
                      required
                      // Use a pattern to require at least 8 chars and 1 special char
                      pattern="^(?=.*[^A-Za-z0-9]).{8,}$"
                      type={showPassword ? "text" : "password"}
                      placeholder="Wachtwoord"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      disabled={isLoading}
                      name="password"
                      autoComplete="new-password"
                      aria-describedby="toon-wachtwoord"
                    />
                    {/* Toggle show/hide password with Eye / EyeSlash icons */}
                    <Button
                      id="toon-wachtwoord"
                      variant="outline-gray"
                      disabled={isLoading}
                      className="p-2 border-0 text-body me-3 rounded-pill d-flex align-items-center position-absolute end-0 top-50 translate-middle-y "
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <EyeSlash /> : <Eye />}
                    </Button>
                    {/* <Form.Control.Feedback type="invalid">
                        Een wachtwoord moet minimaal uit 8 karakters bestaan en
                        ten minste één speciaal teken.
                      </Form.Control.Feedback> */}
                  </FloatingLabel>

                  {/* Password Criteria Checklist */}
                  <Form.Text className="text-muted mt-1 d-block">
                    <div
                      className={`d-flex align-items-center gap-1 ${
                        meetsLength ? "text-success" : ""
                      }`}
                    >
                      {meetsLength ? <CheckCircleFill /> : <CheckCircle />}
                      Minimaal 8 tekens
                    </div>
                    <div
                      className={`d-flex align-items-center gap-1 ${
                        meetsDigit ? "text-success" : ""
                      }`}
                    >
                      {meetsDigit ? <CheckCircleFill /> : <CheckCircle />}
                      Ten minste één cijfer
                    </div>
                    <div
                      className={`d-flex align-items-center gap-1 ${
                        meetsSpecial ? "text-success" : ""
                      }`}
                    >
                      {meetsSpecial ? <CheckCircleFill /> : <CheckCircle />}
                      Ten minste één speciaal teken
                    </div>
                  </Form.Text>
                </Form.Group>

                {/* ========== Confirm Password Field ========== */}
                <Form.Group controlId="floatingPasswordConfirm">
                  <FloatingLabel label="Wachtwoord herhalen">
                    <Form.Control
                      required
                      type="password"
                      placeholder="Wachtwoord herhalen"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      disabled={isLoading}
                      autoComplete="new-password"
                    />
                    <Form.Control.Feedback type="invalid">
                      Vul alstublieft het wachtwoord nogmaals in.
                    </Form.Control.Feedback>

                    <Form.Text className="text-muted">
                      Herhaal wachtwoord (ter controle)
                    </Form.Text>
                  </FloatingLabel>
                </Form.Group>

                {/* 
                  If you want to ensure password === confirmPassword,
                  you can do a custom check in handleRegister or
                  set a custom validity in onChange.
                */}

                {/* ========== Terms & Conditions Checkbox ========== */}
                <Form.Group controlId="formBasicCheckbox">
                  <div className="d-flex gap-2">
                    <Form.Check
                      required
                      type="checkbox"
                      label="Ik accepteer de"
                      disabled={isLoading}
                      feedback="Je moet de voorwaarden accepteren voordat je kunt registreren."
                      feedbackType="invalid"
                    />
                    <Link
                      to="/forgot-password"
                      className="link-underline link-underline-opacity-0 link-underline-opacity-75-hover"
                    >
                      Gebruiksvoorwaarden en Privacyverklaring
                    </Link>
                  </div>
                </Form.Group>

                {/* ========== Submit Button ========== */}
                <Button
                  variant="primary"
                  type="submit"
                  size="lg"
                  className="w-100 rounded-2"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <>
                      <Spinner animation="border" role="status" size="sm">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>{" "}
                      Bezig met registreren...
                    </>
                  ) : (
                    "Maak account aan"
                  )}
                </Button>
              </Stack>
            </Form>

            {/* Already have an account? */}
            <div className="d-flex align-item-center justify-content-center flex-wrap gap-2">
              <p className="mb-0 text-body-secondary">Heb je al een account?</p>
              <Link
                className="link-underline link-underline-opacity-0 link-underline-opacity-75-hover"
                to="/login"
              >
                Login met je account
              </Link>
            </div>
          </Stack>
        </Col>
      </Row>
    </Container>
  );
}

export default RegisterPage;
