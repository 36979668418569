import React from "react";

import PlaceholderImage from "../assets/placeholder.svg";

import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Placeholder from "react-bootstrap/Placeholder";

function LoadingPlaceholder() {
  return (
    <>
      {Array.from({ length: 12 }).map((_, idx) => (
        <Col key={idx}>
          <Card className="courses shadow border-0 h-100">
            <Card.Img variant="top" src={PlaceholderImage} />
            <Card.Body>
              <Placeholder as={Card.Title} animation="glow">
                <Placeholder xs={6} />
              </Placeholder>
              <Placeholder as={Card.Text} animation="glow" className="mb-5">
                <Placeholder xs={7} /> <Placeholder xs={4} />{" "}
                <Placeholder xs={4} /> <Placeholder xs={6} />{" "}
                <Placeholder xs={8} />
              </Placeholder>
              <Placeholder.Button variant="secondary" xs={6} />
            </Card.Body>
          </Card>
        </Col>
      ))}
    </>
  );
}

export default LoadingPlaceholder;
